@charset "utf-8";

html {
  touch-action: pan-y;
  zoom: reset;
  -ms-content-zooming: none;
  -ms-user-select: none;
  -ms-touch-action: pan-y;
  -ms-scroll-limit-y-maximum: 1px;
  -ms-scroll-limit-y-minimum: 1px;
}

.alertMessage {
  flex: 0 0 50%;
  max-width: 100%;
}

input.largerCheckbox {
  width: 20px;
  height: 20px;
  display: block
}

.alert-new {

  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;

}

.alert-danger-new {
  color: #aa0909;
  background-color: #ffffff;
}

.alert-success-new {
  color: #077446;
  background-color: #ffffff;
}

body {

  position: relative;
  margin: 0px;
  padding: 0px;
  font-size: 11px;
  line-height: 17px;
  letter-spacing: normal;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Segoe UI', 'Helvetica Neue', sans-serif;
  overflow-x: hidden;
  height: 100%;
  color: #595959 !important;
}

.event-data .table td .table th {
  vertical-align: middle;
  padding: 0.25rem 0.25rem 0.25rem 0rem !important;
  line-height: 1rem !important;
  font-size: 12px !important;
}

.event-list {
  font-size: 11px !important;
  font-family: 'Segoe UI';
  font-weight: 400;
}

.event-list td {
  padding: 6px 1rem !important;
  font-weight: 400 !important;
  vertical-align: middle !important;
}

.event-list button {
  width: 2rem !important;
  height: 30px;
}

.event-list button span {
  font-size: 14px !important;
}

.event-btn {
  width: 100px;
  height: 30px;
  max-width: 100%;
}

.vertical-line {
  border-right: 2px solid #e2e2e2;
  height: 1.5rem;
}

.p-inputtext:enabled:focus {
  box-shadow: none !important;
  border: 1px solid #ced4da;
}

#search_input {
  color: #b0b0b0 !important;
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  background-color: #fff;
  background-clip: padding-box;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  height: 22px;
}

.p-datatable .p-datatable-tbody>tr>td {
  white-space: nowrap;
  cursor: pointer;
}
::placeholder {
  color: #939292!important;
  font-size: 13px !important;
  font-family: 'Segoe UI';
  font-weight: 400;
}

.form-control-new::placeholder {
  color: #939292  !important;
  font-size: 13px !important;
  font-family: 'Segoe UI';
  font-weight: 400;
}

.p-datatable .p-datatable-thead>tr>th {
  font-weight: bold !important;
  font-size: 14px !important;

}

.p-inputtext::placeholder {
  color: #939292 !important;
  font-size: 13px !important;
  font-family: 'Segoe UI';
  font-weight: 400;
}

.form-group.has-search {
  position: relative;
  margin-bottom: 0px;
}

.has-search .form-control {
  background-color: #fff;
  position: relative;
}

.p-datatable .p-datatable-tbody>tr>td {
  font-size: 13px !important;
}

.col-form-label {
  font-size: 13px !important;
  font-family: 'Open Sans', sans-serif !important;
  font-weight: 600;
}

.p-dialog.p-confirm-dialog .p-confirm-dialog-icon {
  font-size: 1rem !important;
}

.p-dialog.p-confirm-dialog .p-confirm-dialog-icon {
  font-size: 1rem;
}

.p-dialog .p-dialog-header .p-dialog-title {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Segoe UI', 'Helvetica Neue', sans-serif;
  font-weight: 600;
  font-size: 16px !important;

}

.p-dialog.p-confirm-dialog .p-confirm-dialog-message {
  margin-left: 6px !important;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Segoe UI', 'Helvetica Neue', sans-serif;
}

input:-internal-autofill-selected {
  background-color: white !important;
}

.p-dialog .p-dialog-content p {
  line-height: 25px;
}

.user-popup-header {
  padding: 10px 20px 0px;
}

.popup-title h1 {
  font-size: 22px;
  line-height: 25px;
  margin-bottom: 10px;
}

.savebtn-loading {
  position: absolute;
  top: 6px;
  left: 10px;
}

.savebtn-loading .spinner-border {
  width: 12px;
  height: 12px;
  vertical-align: text-bottom;
  border: 2px solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
}

.sccuessmessage {
  font-size: 12px;
  line-height: 17px;
  color: #077446;

}

input:focus,
input:focus-visible {
  outline: none !important;
}

.p-button.p-component.p-confirm-dialog-accept.p-button {
  background-color: #e72744 !important;
  border: 1px solid #e72744 !important;
  color: #fff;
  padding: 7px 18px;
  margin-right: 12px;
  border-radius: 4px;
}

.p-button.p-component.p-confirm-dialog-reject.p-button-text.p-button-text {
  background-color: #e72744 !important;
  border: 1px solid #e72744 !important;
  color: #fff;
  border-radius: 4px;
  padding: 7px 18px;
  margin-right: 12px;
}

::placeholder {
  color: #939292 !important;
}

.p-button.p-component.p-confirm-dialog-reject.p-button-text.p-button-text:hover {
  color: white !important;
}

.errorpopup {
  min-width: 20%;
  max-width: 80%;
}

.errorpopup.p-dialog .p-dialog-header {
  padding: 1.5rem 1rem 1rem;

}

.errorpopup.delete-popup {
  width: 600px;
  min-width: 30%;
  position: fixed;
}

.errorpopup.delete-popup .p-dialog-content {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

input::file-selector-button {
  background: #dee5f5;
  color: #090909;
  border-radius: 12px;
  width: 100px;
  height: 30px;
  border: 1px solid #dee5f5;
  font-weight: 500;
  font-size: 13px !important;
  letter-spacing: normal !important;
  font-family: 'Segoe UI';
  font-weight: 500;
}

.event-table {
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  position: relative;
}

.dashboard .dash-top-event {
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  min-height: 780px;
}

.table-loading .spinner-border {
  width: 20px;
  height: 20px;
  margin-right: 7px;
  vertical-align: text-bottom;
  border: 2px solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: spinner-border 0.75s linear infinite;
}

.table-loading {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.table-loading b {
  font-size: 14px;
}

.p-button:focus {
  box-shadow: none;
}

.box-body .p-inputtext {
  padding: 7px 30px;
}

.box-body .p-input-icon-right .pi-search {
  left: 30px;
  width: 20px;
}

.button-tabs .marginL-5 {
  margin-left: 5px;
}

.pointer {
  cursor: pointer;
}

label[data-shrink=false]+.MuiInputBase-formControl .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 0px;
}

input.middle:focus {
  outline-width: 0;
}

img {
  vertical-align: middle;
  border-style: none;
}

.card-body {
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem;
}

.p-datatable-wrapper table {
  width: 100%;
  margin-bottom: 1rem;
  border-collapse: collapse;
}

.padd-table tr:nth-child(even) {
  background-color: #f2f2f2;
}

.padd-table .p-datatable-tbody>tr>td {
  padding: 1.4rem !important;
}

@media (max-width: 1200px) and (min-width: 320px) {
  .alertMessage .alert {
    width: 100%;
    width: 100%;
    text-align: center;
  }
}

.box-body .p-datatable table {
  table-layout: inherit !important;
}

.bg-white-table {
  background-color: #ffff;
  padding: 0px;
}

.p-component {
  font-family: 'Segoe UI';
}

.p-paginator-pages {
  display: flex;
  flex-direction: row;
}

#search_input::placeholder {
  opacity: 1 !important;
  color: #939292 !important;
  font-size: 13px !important;
  font-weight: 400 !important;
  font-family: 'Segoe UI';
}

.file-loading input {
  width: 185px;
}

.p-dialog .p-dialog-footer,
.p-dialog .p-dialog-content {
  padding: 15px 15px 15px 15px !important
}

.p-dialog .p-dialog-header .p-dialog-header-icon:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none;
}


.p-dialog-header {
  padding: 20px 20px 0px 20px !important;
}


.modal-backdrop .datepicker {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 0;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}

.modal-backdrop.show {
  opacity: 0;
}

.p-datepicker.p-component.p-connected-overlay-enter-done {
  z-index: 1111 !important;
}

.p-component.p-password-input {
  width: 100% !important;
  margin-bottom: -7px !important;
}

.react-date-picker__calendar {
  z-index: 1111 !important;
}

::-ms-input-placeholder {
  /* Edge 12-18 */
  color: red;
}

.p-inputtext.p-component::placeholder {
  color: #939292 !important;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Segoe UI', 'Helvetica Neue', sans-serif;
}
.p-inputtext.p-component {
  color: #bfbfbf !important;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Segoe UI', 'Helvetica Neue', sans-serif;
}
input {
  color: #3b3a3a !important;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Segoe UI', 'Helvetica Neue', sans-serif;
}
.input-text {
  color: #3b3a3a !important;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Segoe UI', 'Helvetica Neue', sans-serif;
}
.dashboard{
  position: relative;
}
.event-table .p-datatable .p-datatable-tbody > tr{
  color: #000;
}
.p-toast.p-component{
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Segoe UI', 'Helvetica Neue', sans-serif;
}

.dropdown-menu-new.show {
  display: block;
  text-align: center;
  margin-top: 0px;
}

.drop-menu-width {
  min-width: 7rem !important;
}

.dropdown-menu-new {
  position: absolute;
  top: 0;
  right: 0 !important;
  z-index: 1000;
  display: none;
  float: left;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
  transform: translate3d(-10px, 38px, 0px);
  will-change: transofarm;
}
