@import "./assets//css/bootstrap.min.css";
@import "./assets//css/style.css";


.btn-secondary {
  background-color: #e72744 !important;
  border: 1px solid #e72744 !important;
  color: #fff;
  padding: 6px 12px;
  margin-left: "30px";
  border-radius: 6px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Segoe UI', 'Helvetica Neue', sans-serif;
}

.btn-rounded-hash {
  position: relative;
  background-color: #f7f8fa;
  border-radius: 20px;
  height: 30px;
  width: 30px;
  align-items: center;
  justify-content: center;
  display: flex;
}

.btn-primary {
  background-color: #88a6e838 !important;
  border: 1px solid #88a6e838;
  color: #000;
  padding: 6px 10px;
  margin-left: 12px;
  margin-right: 10px;
  border-radius: 6px;
  font-size: 1.25rem;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Segoe UI', 'Helvetica Neue', sans-serif;
  display: inline-flex;
}

.p-button:enabled:hover,
.p-button:not(button):not(a):not(.p-disabled):hover {
  color: #f7f8fa !important;
  background-color: #14213d !important;
  border-color: #14213d !important;
}


.sidebar-wrapper {
  background: rgba(158, 169, 240, 0.08);
  color: black;
  transition: all 0.3s;
  box-shadow: 0px 4px 57px 2px rgba(0, 0, 0, 0.15);
}

.edit-button {
  height: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.userevent-from {
  display: flex !important;
  height: 80vh;
  justify-content: center !important;
  align-items: center !important;
  text-align: center !important;
  margin-left: 162px;
  margin-top: 55px;
  background: linear-gradient(180deg, rgba(252, 17, 200, 0.05) -23.33%, rgba(136, 166, 232, 0.16) 152%);
  box-shadow: 0px 4px 20px 2px rgba(29, 27, 27, 0.11);
  border-radius: 5px;
}

.width {
  width: 100%;
}

.Input-width {
  width: 90%;
  margin-right: 0px;
}

.lable-css {
  font-size: 14px;
  justify-content: flex-start;
  display: flex;
  font-weight: bold;
}

.btn-upsert {
  text-align: center;
  display: flex;
  justify-content: center;
}

.text-color-black {
  color: #000 !important;
}

.input-box {
  width: 100%;
}

.btn_position {
  display: inline-flex;
  justify-content: flex-end;
}

.footer_QRCode {
  text-align: center;
}


.img_size_QRcode {
  height: 20px;
}


.padding_QRcode {
  padding: 0px 9px 0px 9px;
}

.thankyou-main {
  min-height: 100vh;
  background-image: url("../src/assets/img/red-christmas-gifts-with-white-ribbon_24972-649.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.welcome_position {
  display: flex;
  justify-content: center;
}

.thankyou-pt {
  padding-top: 18%;
}

@media screen and (min-width: 320px) and (max-width: 768px) {
  .userevent-from {
    display: flex !important;
    height: 80vh;
    justify-content: center !important;
    align-items: center !important;
    text-align: center !important;
    margin-right: auto;
    margin-left: auto;
    margin-top: 25px;
    background: linear-gradient(180deg, rgba(252, 17, 200, 0.05) -23.33%, rgba(136, 166, 232, 0.16) 152%);
    box-shadow: 0px 4px 20px 2px rgba(29, 27, 27, 0.11);
    border-radius: 5px;
  }

  .lable-css {
    font-size: 12px;
    justify-content: flex-start;
    display: flex;
  }

  .mobile-lable {
    margin-top: 12px;
  }

  .eventlist-card {
    border: 2px solid #212529;
    border-radius: 13px;
    margin-left: 5px;
    margin-right: 5px;
  }

  .eventlist-title {
    border: 1px solid rgba(34, 66, 115, 0.4);
  }

  .text-9 {
    font-size: 14px;
    font-weight: normal;
  }

  .text-12 {
    font-size: 14px;
    font-weight: normal;
  }

  .padding-10 {
    padding: 10px;
  }

  .padding-15 {
    padding: 15px;
  }

  .btn-w {
    margin-left: 10px;
  }

  .mobile_QR {
    width: 59vw;
    height: 74vw;
    margin: 0px;
  }

  .header_border {
    border-bottom: 0px solid rgba(0, 0, 0, 0.35);
  }

  .thankyou-main {
    min-height: 100vh;
    background-image: url("../src/assets/img/red-christmas-gifts-with-white-ribbon_24972-649.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }

  .thankyou-pt {
    padding-top: 50%;
  }

  .dropdown-item {
    display: block;
    width: 100%;
    padding: 0.25rem 1.5rem;
    clear: both;
    font-weight: 400;
    color: #212529;
    text-align: inherit;
    white-space: nowrap;
    background-color: transparent;
    border: 0;
  }

  .mobilesearchbar_width {
    width: 18rem;
  }

  .btn-secondary {
    background-color: #e72744 !important;
    border: 1px solid #e72744 !important;
    color: #fff;
    padding: 6px 12px;
    border-radius: 6px;
  }

  .btn-secondary-eventlist {
    background-color: #e72744 !important;
    border: 1px solid #e72744 !important;
    color: #fff;
    padding: 6px 10px;
    border-radius: 6px;
    display: inline-flex;

  }

  .btn-primary-eventlist {
    background-color: #88a6e838;
    border: 1px solid #88a6e838;
    color: #000;
    padding: 6px 10px;
    border-radius: 6px;
    margin-right: 0px;
    margin-left: -4px;
    font-size: 1.25rem;
    display: inline-flex;
  }

  .btn-primary {
    background-color: #88a6e838;
    border: 1px solid #88a6e838;
    color: #000;
    padding: 6px 12px;
    border-radius: 6px;
    margin-right: 0px;
    margin-left: 0px;
    font-size: 1.25rem;
  }

  .padding-btn {
    padding: 8px 0px 0px 20px;
  }

  .mobilesearchbar_width_eventguestlist {
    width: 100%;
  }

  .padding-search-guestlist {
    padding: 13px 5px 0px 5px;
  }

  .table-loading {
    margin: 0;
    position: absolute;
    top: 70%;
    left: 60%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }

  .img_size_QRcode {
    margin: 10px 7px 5px 3px;
    height: 20px;
  }

  .header {
    padding: 5px 0px 0px 0px;
    display: flex;
    background-color: #14213D;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
  }

  .padding-right {
    padding-right: 12px;
  }

  .btn-rounded-hash-header {
    background-color: #f7f8fa;
    border-radius: 20px;
    height: 25px;
    width: 25px;
  }

  .padding-bottom-footer {
    padding-bottom: 80px !important;
  }

  .header_text_Name {
    color: #000;
    font-size: 15px !important;
    font-weight: bold;
    justify-content: center;
    display: flex;
  }

  .EventGuestList-title {
    padding-top: 11px;
    padding-bottom: 11px;
    display: flex;
    justify-content: center;
    font-weight: 500;
    font-size: 17px;
  }
  .main-body-border-eventguestpage{
    border: 2px solid #95aad1d9;
    margin: 24px;
    margin-top: 72px !important;
    margin-bottom: 70px;
  }
}

.margin_row {
  margin-left: 20px;
}

.btn_position {
  justify-content: flex-end;
  display: inline-flex;
}

.searchbar_width {
  width: 30rem;
  margin-left: 15px;
}

.card.main-body-border {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.event_btn {
  display: inline-flex;
  justify-content: flex-end;
}

.searchbox {
  justify-content: flex-end;
  display: flex;
}

.searchbox .p-input-icon-left {
  margin-right: 34px;
  position: relative;
}

.searchbox .pi-search::before {
  content: "\e908";
  position: absolute;
  top: 2px;
}

.main-body-border {
  border: 2px solid #95aad1d9;
  margin: 24px;
  margin-top: 72px;
  margin-bottom: 70px;
}
.main-body-border-eventguestpage{
  border: 2px solid #95aad1d9;
  margin: 24px;
  margin-top: 30px;
  margin-bottom: 70px;
}
.card.main-body-border-eventguestpage {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.poweredby-text {
  color: black;
  font-size: 13px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Segoe UI', 'Helvetica Neue', sans-serif;
}

.btn_space {
  margin-left: 15px;
}

.login-btn {
  justify-content: center
}

.login-btn svg {
  margin-right: 10px;
}

.table-hover tbody tr:hover {
  background-color: #212529;
  color: #fff !important;
}

.QRcode_position {
  display: flex;
  justify-content: center;
}

/* Footer CSS */
.poweredby-text-footer {
  color: #fff;
  font-size: 13px;
}

.img-size-footer {
  margin: 13px 34px 13px 3px;
  height: 20px;
}

.footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: #14213D;
  color: #212529;
  text-align: right;
  height: 50px;
}

/* Header CSS */
.header {
  padding: 11px 0px 11px 23px;
  background-color: #14213D;

}

.header_text {
  color: #fff;
  font-size: 22px;
  font-weight: 600;

}

.header_text_Name {
  color: #000;
  font-size: 22px;
  font-weight: bold;
  justify-content: center;
  display: flex;
}

.header_profileimage {
  width: 23px;
  margin-right: 8px;
  border-radius: 10px;
}

.header-join-guest-list {
  vertical-align: middle;
  padding: 20px 20px 0px 50px;
}

.header_border {
  border-bottom: 1px solid rgba(0, 0, 0, 0.35);
}

.welcome_text {
  font-size: 15px;
  color: #fff;
  margin-right: 10px;
  font-weight: bold;
}

.btn-rounded-hash-header {
  background-color: #f7f8fa;
  border-radius: 20px;
  height: 25px;
  width: 25px;

}

.logout-btn {
  color: #e72744;
  font-size: 18px;
  margin-left: 2px;
  margin-top: -2px;
  margin-bottom: 0px;
  justify-content: center;
  align-content: center;
  display: flex;
}

.thankyou-container {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #14213D;
}

.thankyou-page {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 400px;
  width: 350px;
  height: 230px;
  background: #ffffff;
  box-shadow: 0px 4px 20px 2px rgba(226, 158, 158, 0.11);
  border-radius: 40px;
  padding-top: 50px;
}

.thank-you-text {
  font-size: 40px;
  font-weight: 800;
  color: black;
  text-shadow: 1px 1px;


}

.text-2 {
  font-size: 14px;
  font-weight: 600;
  display: flex;
  justify-content: center;
  color: #212529;
  padding-top: 10px;
}

.text-1 {
  font-size: 14px;
  font-weight: 600;
  display: flex;
  justify-content: center;
  color: #212529;
  padding-top: 18px;
}


/* media */
@media (min-width: 320px) and (max-width: 767px) {
  .thankyou-container {
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #14213d;
    border: 1px solid rgba(0, 0, 0, 0.06);
  }

  .thankyou-page {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 292px;
    min-width: 292px;
    height: 240px;
    background: #ffffff;
    box-shadow: 0px 4px 20px 2px rgba(226, 158, 158, 0.11);
    border-radius: 40px;
  }

  .thank-you-text {
    font-size: 30px;
    font-weight: 800;
    color: black;
    text-shadow: 1px 1px;
  }

  .text-2 {
    font-size: 12px;
    font-weight: 600;
    display: flex;
    justify-content: center;
    color: #212529;
    padding-top: 8px;
  }

  .text-1 {
    font-size: 12px;
    font-weight: 600;
    display: flex;
    justify-content: center;
    color: #212529;
    padding-top: 15px;
  }

  .spinner-position {
    margin-top: 100px !important;
  }
  .p-datepicker-next{
    color: black !important;
  }
  .p-datepicker-prev{
    color: black !important;
  }
}

.table-loading {
  margin: 0;
  position: absolute;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.guest-table {
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  position: relative;
}

.header_profileimage.btn-rounded-hash-header.company-icon {
  position: absolute;
  bottom: -2px;
}

.header_profileimage.btn-rounded-hash-header.company-icon-eventguest {
  position: absolute;
  bottom: 8px;
}

.header_text {
  margin-left: 36px;
}

.event-table .p-datatable .p-datatable-tbody>tr>td {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100px;

}

.loader-container {
  width: 100%;
  height: 100%;
  position: fixed;
  background: url("../src/assets/img/GiftImageGif.gif") center no-repeat;
  z-index: 1;
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center;
  top: 0px;
  left: 0px;
}

.spinner-position {
  margin-top: 500px;
}

.p-dialog-mask {
  background-color: #706b6bf7 !important;
  transition-property: background-color;
}

.p-component-overlay-enter {
  animation: none !important;
}

.randomguest-container {
  min-height: 75vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(180deg, rgba(204, 245, 239, 0.418) -23.33%, rgba(236, 163, 218, 0) 152%), url("https://d2jgb8zkyyxkam.cloudfront.net/RandomGuestList/BackgroundFile.webp");
  background-position: center;
}

.randomguest-page {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 400px;
  width: 350px;
  height: 230px;
  background: #ffffff;
  box-shadow: 0px 4px 20px 2px rgba(226, 158, 158, 0.11);
  border-radius: 40px;
  padding-top: 50px;
  font-family: 'Sofia', sans-serif;
  font-size: 30px;
}

.randomguest-text {
  font-size: 40px;
  font-weight: 800;
  color: black;
  text-shadow: 1px 1px;

}

.card-css {
  text-align: center;
  border-radius: 25px;
  box-shadow: 2px 2px 11px 0px #f57792e3;
  margin-bottom: 15px;
  font-family: cursive;
  text-transform: capitalize;
  text-shadow: 2px 1px #ff000052;

}

.svg {
  margin-bottom: 7px !important;
}

.pagenotfound {
  justify-content: center;
  display: flex;
  margin-top: 100px;
}

.input-text {
  width: 100%;
  font-size: 14px;
}

.main-screen {
  /* padding-top: 50px; */
  padding-bottom: 100px;
}

.fixed-header {
  background-color: #14213D;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
  color: white;
  padding: 13px 30px 0px 25px;
  height: 50px;
}

.header-left {
  float: left;
}

.header-right {
  float: right;

  display: flex;
}

/* CSS for mobile */
@media only screen and (max-width: 767px) {

  body,
  html {
    overflow-x: hidden;
    margin: 0;
    /* Ensure no default margin causing overflow */
    padding: 0;
    /* Ensure no default padding causing overflow */
  }
}

.p-inline-message-text {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Segoe UI', 'Helvetica Neue', sans-serif;
}

.p-confirm-dialog-accept.p-button.p-component {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Segoe UI', 'Helvetica Neue', sans-serif;
}

.p-confirm-dialog-reject.p-button.p-component {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Segoe UI', 'Helvetica Neue', sans-serif;
}

.p-datepicker-calendar {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Segoe UI', 'Helvetica Neue', sans-serif;
}


@media (min-width: 768px) and (max-width:1800px) {}

.btn.btn-primary.btn_space.position-backbtn {
  position: absolute;
  top: 11px;
  right: 11px;
}

.randomguest-container {
  position: relative;
}

.btn.btn-primary-eventlist.position-backbtn {
  position: absolute;
  top: 0px;
  right: 0px;
}

.oops {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Segoe UI', 'Helvetica Neue', sans-serif;

}

@media(max-width:800px) {
  .randomguest-container {
    /* margin-top: 55px; */
  }

  .btn.btn-primary-eventlist.position-backbtn {

    right: 8px;
  }

  .fixed-header {
    padding: 13px 6px 0px 13px;
  }

  .upsertevent_padding1 {
    padding-top: 65px;
    text-align: center;
  }

  .card-css {
    text-align: center;
    border-radius: 25px;
    box-shadow: 2px 2px 11px 0px #f57792e3;
    margin-bottom: 15px;
    font-family: 'LessCursiveFont', sans-serif;
    text-transform: capitalize;
    text-shadow: 2px 1px #ff000052;
  }
}

.dashboard {
  margin-bottom: 60px
}
.p-datepicker-next{
  color: black;
}
.p-datepicker-prev{
  color: black;
}
.p-dialog-footer button:focus{
  outline: none !important;
}