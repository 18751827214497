html {
    font-size: 14px;
}

.login-main body {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-weight: normal;
    background: var(--surface-ground);
    color: var(--text-color);
    padding: 1rem;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.login-main .card {
    background: var(--surface-card);
    padding: 2rem;
    border-radius: 10px;
    margin-bottom: 1rem;

}

.login-main {
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Segoe UI', 'Helvetica Neue', sans-serif;
    font-style: normal;
    background-image: url("../../assets/img/red-christmas-gifts-with-white-ribbon_24972-649.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.login-main .card {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    min-width: 380px;
    width: 380px;
    height: 445px;
    background: #ffffff;
    box-shadow: 0px 4px 20px 2px rgba(29, 27, 27, 0.11);
    border-radius: 10px;
    background-repeat: no-repeat;
    background-position-x: bottom;
}

.login-main .card label {
    font-size: 16px;
    line-height: 20px;
    font-weight: 600;
}

.button-lable-login{
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Segoe UI', 'Helvetica Neue', sans-serif;
}

.login-btn {
    border-radius: 8px;
    font-size: 16px;
    line-height: 19px;
    background-color: #e72744;
    border: 1px solid #e72744;
    padding: 12px;
    margin-bottom: 12px;
    margin-top: 30px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Segoe UI', 'Helvetica Neue', sans-serif;
}

.login-header {
    margin-bottom: 20px;
    border-bottom: 1px solid #e72744;
    margin-top: 10px;
}

.login-header h2 {
    font-size: 22px;
    line-height: 25px;
    font-weight: 700;
    color: #545b62;
    letter-spacing: 0.8px;
    margin-bottom: 10px;
}


.login-main .card .fa {
    color: #e72744;
}
/* CSS for mobile */
@media only screen and (max-width: 767px) {
    .login-main {
        overflow: hidden;
    }
}

.p-inputtext.p-component {
    color: #3b3a3a !important;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Segoe UI', 'Helvetica Neue', sans-serif;
    font-size: 14px;
    font-weight: 400;
    /* margin-left: -6px; */
}